// autoviz-component-monorepo/packages/product-catalog/src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import
  {
    createBrowserRouter,
    RouterProvider
  } from 'react-router-dom';
import DynamicComponentLoader from './components/dynamic-component-loader';
import { Toaster } from './components/ui/toaster';
import './globals.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <DynamicComponentLoader componentNameAsProp='home' />,
  },
  {
    path: 'catalog',
    element: <DynamicComponentLoader componentNameAsProp='product-catalog' />,
    children: [{
      path: 'wheel/:styleId/:wheelId?',
      element: <DynamicComponentLoader componentNameAsProp='product-detail' />,
    }]
  },
  {
    path: 'configurator/:styleId?/:wheelId?',
    element: <DynamicComponentLoader componentNameAsProp='wheel-configurator' />
  },
]);


const root = ReactDOM.createRoot(document.getElementById('autoviz_product_catalog_root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
    </QueryClientProvider>
  </React.StrictMode>
);
