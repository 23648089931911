// autoviz-component-monorepo/packages/product-catalog/src/lib/utils.ts
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AutovizDbWheelModel, WheelStyle } from './types';

export const siteRoot =
  process.env.REACT_APP_SITE_ROOT || 'http://localhost:8087';
export const siteRootPortal =
  process.env.REACT_APP_SITE_ROOT_PORTAL || 'http://localhost:8087/portal';
export const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT || `${siteRoot}/api`;
export const portalUploadsUrl =
  process.env.REACT_APP_PORTAL_UPLOADS_URL || `${siteRootPortal}/uploads`;
export const corsAssetLoaderUrl = `${siteRoot}/load/?path=`;
export const productCatalogPath = `/catalog/wheel`;
export const configuratorPath = `/configurator`;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const scrollToTop = () => {
  document
    .getElementById('scroll-area-viewport-target')
    ?.scrollTo({ top: 0, behavior: 'smooth' });
};

export const filterInMemoryProducts = ({
  filters,
  products,
}: {
  filters: any;
  products: any;
}) => {
  console.log(`filters`, filters);
  return products.filter((product: any) => {
    return Object.keys(filters).every((key) => {
      if (filters[key].value) {
        let c1 = product?.wheel_info?.WheelStyles?.[0]?.[key];
        let c2 = filters[key].value;
        console.log(`c`, key, product?.wheel_info?.WheelStyles?.[0], c1, c2);
        return c1 === c2;
      }
      return true;
    });
  });
};

export const imgSrcFromWheelModel = (
  currentAutovizDbWheelModel?: AutovizDbWheelModel | WheelStyle | undefined | null,
) =>
  `${currentAutovizDbWheelModel?.wheel_info?.ImgUrlBase}${currentAutovizDbWheelModel?.wheel_info?.WheelStyles[0].Img0001}` || ''; //@ia add placeholder