// autoviz-component-monorepo/packages/product-catalog/src/components/dynamic-component-loader.tsx

import { useStore, useThemeStore } from "@/lib/store";
import React, { Suspense, lazy, useEffect, useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import productCatalog from "./product-catalog";
import { configuratorPath, productCatalogPath } from "@/lib/utils";

const DynamicComponentLoader = ({componentNameAsProp, props}: {componentNameAsProp?: string, props?: any}) =>
{
  const [searchParams] = useSearchParams();
  const componentName = searchParams.get("component");

  const componentMap: { [key: string]: any } = {
    home: () => import(/* webpackChunkName: "home" */ "./home"),
    "filter-results-bar": () =>
      import(
        /* webpackChunkName: "filter-results-bar" */ "./filter-results-bar"
      ),
    "product-catalog": () =>
      import(/* webpackChunkName: "product-catalog" */ "./product-catalog"),
    "product-detail-popover": () =>
      import(
        /* webpackChunkName: "product-detail-popover" */ "./product-detail-popover"
      ),
    "product-detail": () =>
      import(/* webpackChunkName: "product-detail" */ "./product-detail"),
    "product-list": () =>
      import(/* webpackChunkName: "product-list" */ "./product-list"),
    "quote-request-form": () =>
      import(
        /* webpackChunkName: "quote-request-form" */ "./quote-request-form"
      ),
    "quote-request": () =>
      import(/* webpackChunkName: "quote-request" */ "./quote-request"),
    "scroll-area-dynamic": () =>
      import(
        /* webpackChunkName: "scroll-area-dynamic" */ "./scroll-area-dynamic"
      ),
    "toggle-button-list-grid": () =>
      import(
        /* webpackChunkName: "toggle-button-list-grid" */ "./toggle-button-list-grid"
      ),
    "wheel-card": () =>
      import(/* webpackChunkName: "wheel-card" */ "./wheel-card"),
    "wheel-carousel": () =>
      import(/* webpackChunkName: "wheel-carousel" */ "./wheel-carousel"),
    "wheel-configurator": () =>
      import(
        /* webpackChunkName: "wheel-configurator" */ "./wheel-configurator/wheel-configurator"
      ),
    "wheel-interactives-navigation-list": () =>
      import(
        /* webpackChunkName: "wheel-interactives-navigation-list" */ "./wheel-interactives-navigation-list"
      ),
  };

  const ComponentToRender = useMemo(() => {
    if (componentName && componentMap[componentName]) {
      return lazy(componentMap[componentName]);
    } else if (componentNameAsProp && componentMap[componentNameAsProp]) {
      return lazy(componentMap[componentNameAsProp]);
    }
    return null;
  }, [componentName, componentNameAsProp]);

  const isDark = useThemeStore((state) => state.isDark);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDark]);

  const location = useLocation();
  const setNavigationContextRootPath = useStore(
    (state) => state.setNavigationContextRootPath
  );

  // useEffect(() => {
  //   console.log(`location.pathname`, location.pathname);
  //   setNavigationContextRootPath(
  //     location.pathname.indexOf(productCatalogPath) > -1
  //       ? productCatalogPath
  //       : configuratorPath
  //   );
  // }, [location.pathname]);

  return (
    <div>
      {ComponentToRender ? (
        <Suspense fallback={<></>}>
          <ComponentToRender {...props} />
        </Suspense>
      ) : (
        <div>Component not found</div>
      )}
    </div>
  );
};

export default React.memo(DynamicComponentLoader);